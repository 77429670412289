import React from 'react'
import Inview from 'components/Inview'
import Mark from 'components/Mark'
import * as styles from './styles.module.scss'

const IntroBlock = ({ content }) => {
  const { pretitle, title, body } = content
  return (
    <div className={`${styles.component} block pad-v-standard`}>
      <div className={styles.markWrap}>
        <Mark />
      </div>
      <Inview className={`${styles.container} container grid-12 fade-in up`}>
        <div className={styles.inner}>
          {pretitle && <h5>{pretitle}</h5>}
          <h1>{title}</h1>
          <p>{body}</p>
        </div>
      </Inview>
    </div>
  )
}

export default IntroBlock
