import React from 'react'
import Inview from 'components/Inview'
import Mark from 'components/Mark'
import ButtonLink from 'components/ButtonLink'
import * as styles from './styles.module.scss'
import { isObjectEmpty } from 'js/utils'

const Template = ({ content }) => {
  const { title, body, buttonLink } = content

  return (
    <div className={`${styles.component} block pad-v-standard no-collapse`}>
      <div className={styles.markWrap}>
        <Mark />
      </div>
      <Inview className={`${styles.container} container grid-12 fade-in up`}>
        <div className={styles.wrap}>
          {title && <h2>{title}</h2>}
          {body && <p>{body}</p>}
          {!isObjectEmpty(buttonLink) && (
            <div className={styles.buttonWrap}>
              <ButtonLink content={buttonLink} />
            </div>
          )}
        </div>
      </Inview>
    </div>
  )
}

export default Template
