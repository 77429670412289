// extracted by mini-css-extract-plugin
export var outer = "styles-module--outer--3UozT";
export var markWrap = "styles-module--mark-wrap--8iRdc";
export var container = "styles-module--container--3ROcG";
export var wrap = "styles-module--wrap--21xJz";
export var line1 = "styles-module--line-1--32tob";
export var line2 = "styles-module--line-2--1765g";
export var blockInView = "styles-module--block-in-view--1ePWP";
export var isScrolled = "styles-module--is-scrolled---JXB3";
export var scrollDownWrap = "styles-module--scroll-down-wrap--1wa_z";
export var scrollDown = "styles-module--scroll-down--1lQ-9";
export var localeZh = "styles-module--locale-zh--2FqRJ";