import React, { useState } from 'react'
import ImageWrap from 'components/ImageWrap'
import SwiperCore, {
  Autoplay,
  Navigation,
  Controller,
  EffectFade,
} from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import * as styles from './styles.module.scss'
import { formatDate } from 'js/utils'
import ArrowRightSvg from 'assets/svg/ui/arrow-right.svg'

SwiperCore.use([Autoplay, Navigation, Controller, EffectFade])

const MoreItemsSlideshow = ({ items, locale, isCMS }) => {
  const [controlledSwiper, setControlledSwiper] = useState(null)

  if (!isCMS) {
    return (
      <div className={styles.swiperComponent}>
        <div className='container'>
          <div className={styles.containerInner}>
            <SwiperImages
              items={items}
              locale={locale}
              controlledSwiper={controlledSwiper}
            />
            <SwiperText
              items={items}
              locale={locale}
              setControlledSwiper={setControlledSwiper}
            />
          </div>
        </div>
      </div>
    )
  }
  if (isCMS) {
    return (
      <div className='container'>
        <code>
          Add entries to <a href='/admin/#/collections/post'>Posts</a> and they
          will appear here in reverse date order (with filter if selected).
        </code>
      </div>
    )
  }
}

const SwiperImages = ({ items, locale, controlledSwiper }) => {
  return (
    <>
      <Swiper
        className={styles.swiperImages}
        controller={{ control: controlledSwiper }}
        navigation={{
          nextEl: '.swiper-button-next',
        }}
        loop={true}
        loopAdditionalSlides={1}
        speed={600}
        autoplay={{
          delay: 5000,
        }}
        spaceBetween={12}
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          601: {
            slidesPerView: 1.5,
          },
          1025: {
            slidesPerView: 2.5,
          },
        }}
      >
        <div className={`swiper-button-next ${styles.arrowWrap}`}>
          <ArrowRightSvg />
        </div>

        {items?.map((item, i) => (
          <SwiperSlide key={i}>
            {({ isActive }) => (
              <SlideImage content={item} locale={locale} isActive={isActive} />
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  )
}

const SwiperText = ({ items, locale, setControlledSwiper }) => {
  return (
    <Swiper
      onSwiper={setControlledSwiper}
      effect='fade'
      allowTouchMove={false}
      loop={true}
      loopAdditionalSlides={1}
      breakpoints={{
        0: {
          slidesPerView: 1,
        },
        601: {
          slidesPerView: 1.5,
        },
        1025: {
          slidesPerView: 2.5,
        },
      }}
    >
      {items?.map((item, i) => (
        <SwiperSlide key={i} className={styles.slideText}>
          {({ isActive, isVisible }) => (
            <SlideText content={item} locale={locale} isActive={isActive} isVisible={isVisible} />
          )}
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

const SlideImage = ({ content, isActive }) => {
  const { image, linkExternal } = content
  const isActiveClass = isActive ? styles.isActive : ''
  return (
    <a
      className={`${styles.slideImageInner} ${isActiveClass}`}
      href={linkExternal}
      target='_blank'
    >
      <ImageWrap image={image} aspectRatio={3 / 2} />
    </a>
  )
}

const SlideText = ({ content, locale, isActive, isVisible }) => {
  const { date, title, linkExternal } = content
  const showDate = formatDate(new Date(date), locale)
  const isActiveClass = isActive && isVisible ? styles.isActive : ''
  return (
    <a
      className={`${styles.slideTextInner} ${isActiveClass}`}
      href={linkExternal}
      target='_blank'
    >
      <h6>{showDate}</h6>
      <p className='strong'>{title}</p>
    </a>
  )
}

export default MoreItemsSlideshow
