import React from 'react'
import ButtonLink from 'components/ButtonLink'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const TitleExcerptBlock = ({ content }) => {
  const { pretitle, title, body, buttonLink, align } = content
  const alignClass = align === 'right' ? styles.alignRight : ''
  return (
    <div className='pad-v-standard'>
      <Inview className='container grid-12 fade-in up'>
        <div className={`${styles.inner} ${alignClass}`}>
          {pretitle && <h5>{pretitle}</h5>}
          <h1>{title}</h1>
          <p>{body}</p>
          {buttonLink && (
            <div className={styles.buttonWrap}>
              <ButtonLink content={buttonLink} />
            </div>
          )}
        </div>
      </Inview>
    </div>
  )
}

export default TitleExcerptBlock
