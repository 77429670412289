// extracted by mini-css-extract-plugin
export var footer = "styles-module--footer--22w0l";
export var imageWrap = "styles-module--imageWrap--3g7q_";
export var bgTop = "styles-module--bg-top--RKhae";
export var bgBottom = "styles-module--bg-bottom--fJdGI";
export var grey03 = "styles-module--grey-03--iyh7b";
export var blue = "styles-module--blue--2pzx2";
export var image = "styles-module--image--1PWcm";
export var mid = "styles-module--mid--2YW4_";
export var logo = "styles-module--logo--3hAsK";
export var contact = "styles-module--contact--3Wsm2";
export var address = "styles-module--address--23uv_";
export var socialWrap = "styles-module--social-wrap--Aru0c";
export var copyright = "styles-module--copyright--202pr";
export var inner = "styles-module--inner--lJD3a";