import React, { useState } from 'react'
import SwiperCore, { Autoplay, Pagination, Parallax } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import QuoteLeft from 'assets/svg/quote-left.svg'
import QuoteRight from 'assets/svg/quote-right.svg'
import * as styles from './styles.module.scss'

SwiperCore.use([Autoplay, Pagination, Parallax])

const TestimonialsSlideshow = ({ items }) => {
  return (
    <div className={styles.swiperComponent}>
      <Swiper
        loop={true}
        loopAdditionalSlides={1}
        speed={600}
        autoplay={{
          delay: 5000,
        }}
        slidesPerView={1}
        parallax={true}
        pagination={{
          clickable: true,
          el: `.${styles.pagination}`,
          bulletClass: `swiper-pagination-bullet ${styles.bullet}`,
        }}
      >
        {items.map((item, i) => (
          <SwiperSlide key={i}>
            {({ isActive }) => (
              <Slide content={item.data} isActive={isActive} />
            )}
          </SwiperSlide>
        ))}
        <div className={styles.pagination}></div>
      </Swiper>
    </div>
  )
}

const Slide = ({ content, isActive }) => {
  const { quote, name, jobTitle } = content || {}
  const isActiveClass = isActive ? styles.isActive : ''
  return (
    <div className={isActiveClass}>
      <div className={styles.quote} data-swiper-parallax='80%'>
        <div className={styles.quoteInner}>
          <h4>
            <span
              className={`${styles.quoteChar} ${styles.quoteCharLeft}`}
              data-swiper-parallax={-300}
            >
              <QuoteLeft />
            </span>
            {quote}
            <span
              className={`${styles.quoteChar} ${styles.quoteCharRight}`}
              data-swiper-parallax={300}
            >
              <QuoteRight />
            </span>
          </h4>
        </div>
      </div>
      <div className={styles.source} data-swiper-parallax='100%'>
        <div className={styles.sourceInner}>
          <div>
            <p className={`strong`}>— {name}</p>
            <p className={`${styles.jobTitle} smaller`}>{jobTitle}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TestimonialsSlideshow
