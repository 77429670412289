// extracted by mini-css-extract-plugin
export var swiperComponent = "styles-module--swiper-component--2_Dlq";
export var pagination = "styles-module--pagination--37bAw";
export var jobTitle = "styles-module--job-title--2bPOb";
export var quote = "styles-module--quote--2RtAe";
export var quoteInner = "styles-module--quote-inner--eEk0q";
export var source = "styles-module--source--3mTjo";
export var sourceInner = "styles-module--source-inner--3NcQX";
export var bullet = "styles-module--bullet--PQk_f";
export var isActive = "styles-module--is-active--u8HYA";
export var quoteChar = "styles-module--quote-char--1_Hyx";
export var quoteCharLeft = "styles-module--quote-char-left--1iv89";
export var quoteCharRight = "styles-module--quote-char-right--1ibJv";