import React from 'react'
import ImageWrap from 'components/ImageWrap'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Template = ({ content }) => {
  const { rows } = content
  return (
    <div className='pad-v-standard'>
      <div className='container grid-12'>
        <div className={styles.table}>
          {rows?.map((item, i) => (
            <Row content={item} key={i} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Template

const Row = ({ content }) => {
  const { icon, title, body } = content
  return (
    <Inview className={`${styles.row} fade-in up`}>
      <div className={styles.border} />
      <div className={styles.heading}>
        <h5>{title}</h5>
        <div className={styles.icon}>
          <ImageWrap image={icon} />
        </div>
      </div>
      <div className={styles.body}>
        <p>{body}</p>
      </div>
    </Inview>
  )
}
