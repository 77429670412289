import React from 'react'
import ButtonWrapBlock from 'blocks/ButtonWrapBlock/template'
import ContactBlock from 'blocks/ContactBlock/template'
import DarkModeToggle from 'blocks/DarkModeToggle/template'
import HomeIntroBlock from 'blocks/HomeIntroBlock/template'
import IconColumnsBlock from 'blocks/IconColumnsBlock/template'
import IconTableBlock from 'blocks/IconTableBlock/template'
import InnerImageBlock from 'blocks/InnerImageBlock/template'
import IntroBlock from 'blocks/IntroBlock/template'
import LogoGridBlock from 'blocks/LogoGridBlock/template'
import LogoStripBlock from 'blocks/LogoStripBlock/template'
import NewsListBlock from 'blocks/NewsListBlock/template'
import NewsSlideshowBlock from 'blocks/NewsSlideshowBlock/template'
import PartnerBlock from 'blocks/PartnerBlock/template'
import SpacerBlock from 'blocks/SpacerBlock/template'
import TeamGridBlock from 'blocks/TeamGridBlock/template'
import TeamLeadBlock from 'blocks/TeamLeadBlock/template'
import TestimonialsBlock from 'blocks/TestimonialsBlock/template'
import TextCenteredBlock from 'blocks/TextCenteredBlock/template'
import TextLeftBlock from 'blocks/TextLeftBlock/template'
import TitleExcerptBlock from 'blocks/TitleExcerptBlock/template'
import TitleImageBlock from 'blocks/TitleImageBlock/template'
import Footer from 'components/Footer'
import InviewTheme from 'components/InviewTheme'
import * as styles from './styles.module.scss'
import { useLocalization } from 'gatsby-theme-i18n'

const components = {
  buttonWrapBlock: ButtonWrapBlock,
  contactBlock: ContactBlock,
  darkModeToggle: DarkModeToggle,
  homeIntroBlock: HomeIntroBlock,
  iconColumnsBlock: IconColumnsBlock,
  iconTableBlock: IconTableBlock,
  innerImageBlock: InnerImageBlock,
  introBlock: IntroBlock,
  logoGridBlock: LogoGridBlock,
  logoStripBlock: LogoStripBlock,
  newsListBlock: NewsListBlock,
  newsSlideshowBlock: NewsSlideshowBlock,
  partnerBlock: PartnerBlock,
  spacerBlock: SpacerBlock,
  teamGridBlock: TeamGridBlock,
  teamLeadBlock: TeamLeadBlock,
  testimonialsBlock: TestimonialsBlock,
  textCenteredBlock: TextCenteredBlock,
  textLeftBlock: TextLeftBlock,
  titleExcerptBlock: TitleExcerptBlock,
  titleImageBlock: TitleImageBlock,
}

const PageTemplate = ({ content, locale, layoutProps }) => {
  if (!locale) locale = 'en'

  let { main, footerLayout } = content
  const { processIsDarkMode } = layoutProps || {}

  const createThemeBlocks = (blocks) => {
    let themeBlocks = []
    let currentInnerIndex = 0
    let currentTheme = 'light'
    blocks?.forEach((block, i) => {
      if (block.type === 'darkModeToggle' && currentTheme === 'light') {
        // start a new dark group
        if (i !== 0) {
          currentInnerIndex++
        }
        currentTheme = 'dark'
        themeBlocks[currentInnerIndex] = {
          theme: currentTheme,
          blocks: [],
        }
      } else if (block.type === 'darkModeToggle' && currentTheme === 'dark') {
        // start a new light group
        currentInnerIndex++
        currentTheme = 'light'
        themeBlocks[currentInnerIndex] = {
          theme: currentTheme,
          blocks: [],
        }
      } else if (i === 0) {
        // it's just the first block, with no toggle
        themeBlocks[currentInnerIndex] = {
          theme: currentTheme,
          blocks: [],
        }
        themeBlocks[currentInnerIndex].blocks.push(block)
      } else {
        // just push the block into the current group
        themeBlocks[currentInnerIndex].blocks.push(block)
      }
    })
    return themeBlocks
  }
  const themeBlocks = createThemeBlocks(main)

  return (
    <div className={styles.page}>
      {themeBlocks.map((item, i) => (
        <ThemeGroup
          content={item}
          locale={locale}
          key={i}
          processIsDarkMode={processIsDarkMode}
        />
      ))}
      <Footer footerLayout={footerLayout} locale={locale} />
    </div>
  )
}

const ThemeGroup = ({ content, locale, processIsDarkMode }) => {
  const { theme, blocks } = content
  let setThreshold = 0
  if (blocks.length === 1) {
    setThreshold = 0.8
  }

  return (
    <InviewTheme
      theme={theme}
      processIsDarkMode={processIsDarkMode}
      setThreshold={setThreshold}
    >
      {blocks.map((block, i) => {
        if (components[block.type]) {
          return React.createElement(components[block.type], {
            content: block,
            locale,
            key: i,
          })
        }
      })}
    </InviewTheme>
  )
}

export default PageTemplate
