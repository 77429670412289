import React, { useEffect, useState } from 'react'
import { InView } from 'react-intersection-observer'
import MarkSvg from 'assets/svg/vi/logo-mark-gradient.svg'
import * as styles from './styles.module.scss'

const Component = ({ isHome, isMobileLighter }) => {
  const [isInView, setIsInView] = useState(false)
  const handleChange = (inView, entry) => {
    setIsInView(inView)
  }
  const inViewClass = isInView ? styles.markInView : ''

  const parallax = React.useRef(null)

  useEffect(() => {
    const scrollHandler = () => {
      const element = parallax.current
      if (element) {
        const parent = element.closest('.block')
        if (parent) {
          const parentCenter = parent.offsetTop + parent.offsetHeight / 2
          const windowCenter = window.scrollY + window.innerHeight / 2
          const scrollPos = windowCenter - parentCenter
          const yVal = scrollPos * 0.3
          element.style.transform = `translateY(${yVal}px)`
        }
      }
    }
    window.addEventListener('scroll', scrollHandler)
    return () => window.removeEventListener('scroll', scrollHandler)
  }, [])

  const isHomeClass = isHome ? styles.isHome : ''
  const isMobileLighterClass = isMobileLighter ? styles.isMobileLighter : ''
  return (
    <div ref={parallax}>
      <InView
        onChange={handleChange}
        threshold={0.5}
        className={`${styles.mark} ${inViewClass} ${isHomeClass} ${isMobileLighterClass} `}
        triggerOnce={true}
      >
        <MarkSvg />
      </InView>
    </div>
  )
}

export default Component
