import React from 'react'
import ContactForm from 'components/Forms/ContactForm'
import Social from 'components/Social'
import ImageWrap from 'components/ImageWrap'
import LineLink from 'components/LineLink'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Template = ({ content, locale }) => {
  const { image, address, email, phone } = content
  const localeStrings = {
    en: {
      address: 'Address',
      phone: 'Phone',
      email: 'Email',
    },
    zh: {
      address: '地址',
      phone: '电话',
      email: '邮箱',
    },
  }
  const strings = localeStrings[locale]

  return (
    <div className='pad-v-standard'>
      <div className='container'>
        <div className={styles.wrap}>
          <Inview className={`${styles.form} fade-in up`}>
            <ContactForm locale={locale} />
          </Inview>
          <div className={styles.info}>
            <Inview className={`${styles.image} fade-in up`}>
              <ImageWrap image={image} />
            </Inview>
            <Inview className={`${styles.items} fade-in up`}>
              <div>
                <h6>{strings.address}</h6>
                <p>{address}</p>
              </div>
              <div>
                <h6>{strings.phone}</h6>
                <p>{phone}</p>
              </div>
              <div>
                <h6>{strings.email}</h6>
                <p>
                  <LineLink
                    content={{ to: email, text: email, linkType: 'email' }}
                  />
                </p>
              </div>
            </Inview>
            <Inview className={`${styles.social} fade-in up`}>
              <Social version='light' isLeft={true} />
            </Inview>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Template
