import React from 'react'
import ButtonLink from 'components/ButtonLink'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const ButtonWrapBlock = ({ content }) => {
  const { buttonLink, align } = content || {}
  const alignClass = align === 'center' ? styles.alignCenter : ''

  return (
    <div className='pad-v-standard'>
      <Inview className='container fade-in up'>
        <div className={`${styles.wrap} ${alignClass}`}>
          {buttonLink && <ButtonLink content={buttonLink} />}
        </div>
      </Inview>
    </div>
  )
}

export default ButtonWrapBlock
