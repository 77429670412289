import React from 'react'
import MarkdownWrap from 'components/MarkdownWrap'
import LineLink from 'components/LineLink'
import ImageWrap from 'components/ImageWrap'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const PartnerBlock = ({ content }) => {
  const { pretitle, logo, body, lineLink } = content?.partner?.data || {}
  return (
    <div
      className={`${styles.component} partner-block pad-v-standard no-collapse`}
    >
      <Inview className='fade-in up'>
        <div className={`${styles.title} container grid-12`}>
          <h5>{pretitle}</h5>
        </div>
        <div className={styles.main}>
          <div className={`container grid-12`}>
            <div className={styles.logo}>
              <ImageWrap image={logo} />
            </div>
            <div className={styles.text}>
              <MarkdownWrap body={body} />
              <div className={styles.linkWrap}>
                <p>
                  <LineLink content={lineLink} addGlobeIcon={true} />
                </p>
              </div>
            </div>
          </div>
        </div>
      </Inview>
    </div>
  )
}

export default PartnerBlock
