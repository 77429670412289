import React from 'react'
import ImageWrap from 'components/ImageWrap'
import TextExpand from 'components/TextExpand'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const TeamGridBlock = ({ content, locale }) => {
  const { teamMembers } = content
  return (
    <div className='pad-v-standard'>
      <Inview className='container grid-12 fade-in up'>
        <div className={styles.gridInner}>
          {teamMembers?.map((item, i) => {
            if (item.data) {
              return <Item content={item.data} key={i} i={i} locale={locale} />
            }
          })}
        </div>
      </Inview>
    </div>
  )
}

const Item = ({ content, i, locale }) => {
  const { jobTitle, name, image, bio } = content || {}
  return (
    <div className={`fade-in up stagger-${i * 50 + 500}`}>
      {image && (
        <div className={styles.image}>
          <ImageWrap image={image} aspectRatio={5 / 6} />
        </div>
      )}
      <div className={styles.text}>
        <h4>{name}</h4>
        <h6>{jobTitle}</h6>
        <div className='p-smaller'>
          <TextExpand content={bio} locale={locale} />
        </div>
      </div>
    </div>
  )
}

export default TeamGridBlock
