import React, { useState, useEffect } from 'react'
import Mark from 'components/Mark'
import { InView } from 'react-intersection-observer'
import * as styles from './styles.module.scss'

const Template = ({ content, locale }) => {
  const [isInView, setIsInView] = useState(false)
  const [isScrolled, setIsScrolled] = useState(false)
  const handleChange = (inView, entry) => {
    setIsInView(inView)
  }
  const inViewClass = isInView ? styles.blockInView : ''
  const isScrolledClass = isScrolled ? styles.isScrolled : ''

  const { line1, line2 } = content

  let localeClass = ''
  if (locale === 'zh') localeClass = styles.localeZh

  useEffect(() => {
    const scrollHandler = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true)
      } else {
        setIsScrolled(false)
      }
    }
    window.addEventListener('scroll', scrollHandler)
    return () => window.removeEventListener('scroll', scrollHandler)
  }, [])

  return (
    <InView
      onChange={handleChange}
      threshold={0.5}
      className={`block bg-shade ${styles.outer} ${localeClass}`}
      triggerOnce={true}
    >
      <div className={styles.markWrap}>
        <Mark isHome={true} />
      </div>
      <div className={`container ${styles.container}`}>
        <div className={`${styles.wrap} ${inViewClass} ${isScrolledClass}`}>
          <div className={styles.line1}>
            <h1>{line1}</h1>
          </div>
          <div className={styles.line2}>
            <h1>{line2}</h1>
          </div>
        </div>
      </div>
      <ScrollDown inViewClass={inViewClass} isScrolledClass={isScrolledClass} />
    </InView>
  )
}

const ScrollDown = ({ inViewClass, isScrolledClass }) => {
  const handleScrollDown = (e) => {
    const block = e.target.closest('.block')
    window.scrollTo({
      top: block.offsetHeight,
      behavior: 'smooth',
    })
  }

  return (
    <div
      className={`${styles.scrollDownWrap}  ${inViewClass} ${isScrolledClass}`}
    >
      <div className={`${styles.scrollDown}`} onClick={handleScrollDown} />
    </div>
  )
}

export default Template
