import React from 'react'
import { Helmet } from 'react-helmet'
import { useLocalization } from 'gatsby-theme-i18n'

const Seo = ({ content }) => {
  const { locale } = useLocalization()
  const { title, seo } = content || {}
  const companyName = {
    en: 'ProHR',
    zh: 'ProHR 汇华',
  }
  let pageTitle = title // default
  if (seo?.title) pageTitle = seo?.title // cms override
  const setTitle = `${companyName[locale]} | ${pageTitle}`
  const setDescription = seo?.description || null

  return (
    <Helmet>
      {setTitle && <title>{setTitle}</title>}
      {setDescription && <meta name='description' content={setDescription} />}
      {/* {keywords && <meta name='keywords' content={keywords} />} */}
    </Helmet>
  )
}

export default Seo
