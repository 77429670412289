import React from 'react'
import { InView } from 'react-intersection-observer'

const Component = ({ children, theme, setThreshold, processIsDarkMode }) => {
  const handleChange = (inView) => {
    processIsDarkMode(inView)
  }

  return (
    <InView
      onChange={theme === 'dark' ? handleChange : null}
      threshold={setThreshold}
    >
      {({ inView, ref }) => {
        return <div ref={ref}>{children}</div>
      }}
    </InView>
  )
}

export default Component
