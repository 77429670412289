import React from 'react'
import ImageWrap from 'components/ImageWrap'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Template = ({ content }) => {
  const { logos } = content
  return (
    <div className={`${styles.component} pad-v-standard`}>
      <Inview className='container fade-in up'>
        <div className={`${styles.grid} grid-12`}>
          {logos?.map((item, i) => (
            <Logo content={item.data} key={i} i={i} />
          ))}
        </div>
      </Inview>
    </div>
  )
}

export default Template

const Logo = ({ content, i }) => {
  const { image } = content || {}
  return (
    <Inview className={`${styles.logo} fade-in up stagger-${i * 50 + 500}`}>
      <ImageWrap image={image} aspectRatio={5 / 4} />
    </Inview>
  )
}
