import React from 'react'
import ImageWrap from 'components/ImageWrap'
import ButtonLink from 'components/ButtonLink'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'
import { formatDate } from 'js/utils'

const Template = ({ content, locale }) => {
  let { posts, isCMS } = content
  posts = posts?.map((post) => post.frontmatter[locale])
  if (posts) {
    posts = posts.slice(0, 4)
  }
  return (
    <div className='pad-v-standard'>
      <>
        {!isCMS && (
          <div className={`container grid-12 ${styles.grid}`}>
            {posts?.map((item, i) => (
              <Post content={item} locale={locale} key={i} />
            ))}
          </div>
        )}
        {isCMS && (
          <Inview className='container fade-in up'>
            <code>
              Add entries to <a href='/admin/#/collections/post'>Posts</a> and
              they will appear here in reverse date order.
            </code>
          </Inview>
        )}
      </>
    </div>
  )
}

export default Template

const Post = ({ content, locale }) => {
  const { image, date, title, excerpt, linkExternal } = content
  const localeStrings = {
    en: {
      readMore: 'Read more',
    },
    zh: {
      readMore: '更多内容',
    },
  }
  const strings = localeStrings[locale]
  const link = {
    text: strings.readMore,
    to: linkExternal,
    linkType: 'external',
  }
  const showDate = formatDate(new Date(date), locale)

  return (
    <Inview className={`${styles.post} fade-in up`}>
      <a href={linkExternal} target='_blank'>
        <ImageWrap image={image} aspectRatio={3 / 2} />
      </a>
      <div className={styles.text}>
        <h6>{showDate}</h6>
        <a href={linkExternal} target='_blank'>
          <h4>{title}</h4>
        </a>
        <p className='smaller'>{excerpt}</p>
      </div>
      <div className={styles.linkWrap}>
        <ButtonLink content={link} />
      </div>
    </Inview>
  )
}
