import React from 'react'
import ImageWrap from 'components/ImageWrap'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Template = ({ content }) => {
  const { items } = content
  return (
    <div className='pad-v-standard'>
      <Inview className='container grid-12 fade-in up'>
        <div className={styles.columns}>
          <div className={styles.column}>
            {items?.map((item, i) => {
              if (i % 2 === 0) {
                return <Item content={item} key={i} />
              }
            })}
          </div>
          <div className={styles.column}>
            {items?.map((item, i) => {
              if (i % 2 === 1) {
                return <Item content={item} key={i} />
              }
            })}
          </div>
        </div>
      </Inview>
    </div>
  )
}

export default Template

const Item = ({ content }) => {
  const { icon, title, body } = content
  return (
    <div className={styles.item}>
      <div className={styles.heading}>
        <div className={styles.icon}>
          <ImageWrap image={icon} />
        </div>
        <h5>{title}</h5>
      </div>
      <div className={styles.body}>
        <p className='smaller'>{body}</p>
      </div>
    </div>
  )
}
