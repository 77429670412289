import React from 'react'
import ImageWrap from 'components/ImageWrap'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Template = ({ content }) => {
  const { imageSet } = content || {}
  const { desktop, mobile } = imageSet || {}
  return (
    <div className={`pad-v-standard ${styles.component}`}>
      <Inview className='container grid-12 fade-in up'>
        {desktop && (
          <div className={styles.desktopImage}>
            <ImageWrap image={desktop} />
          </div>
        )}
        {mobile && (
          <div className={styles.mobileImage}>
            <ImageWrap image={mobile} />
          </div>
        )}
      </Inview>
    </div>
  )
}

export default Template
