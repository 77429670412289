import React from 'react'
import Inview from 'components/Inview'
import TestimonialsSlideshow from 'components/Slideshows/TestimonialsSlideshow'
import * as styles from './styles.module.scss'

const Template = ({ content }) => {
  const { testimonials } = content
  if (testimonials) {
    return (
      <div className='pad-v-standard no-collapse'>
        <Inview className='container fade-in up grid-12'>
          <div className={styles.inner}>
            <TestimonialsSlideshow items={testimonials} />
          </div>
        </Inview>
      </div>
    )
  }
  return false
}

export default Template
