import React from 'react'
// import Arrow from 'components/Arrow'
import LightBulbSvg from 'assets/svg/icons/light-bulb.svg'
import MoreItemsSlideshow from 'components/Slideshows/MoreItemsSlideshow'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Template = ({ content, locale }) => {
  let { pretitle, title, excerpt, filter, bgColor, posts, isCMS } = content
  posts = posts?.map((post) => post.frontmatter[locale])
  if (filter && posts) {
    if (filter === 'archive') {
      posts = posts.slice(4)
    } else {
      posts = posts.filter((post) => post.category?.includes(filter))
    }
  }
  let collapseClass = ''
  let bgColorClass = ''
  if (bgColor === 'grey-03') {
    collapseClass = 'no-collapse'
    bgColorClass = styles.grey03
  }

  return (
    <div
      className={`pad-v-standard ${styles.component} ${collapseClass} ${bgColorClass}`}
    >
      <Inview className='container fade-in up'>
        <div className={styles.text}>
          {pretitle && (
            <h5>
              <LightBulbSvg />
              {pretitle}
            </h5>
          )}
          {title && <h3>{title}</h3>}
          {excerpt && <p>{excerpt}</p>}
        </div>
      </Inview>
      <Inview className='fade-in up'>
        <MoreItemsSlideshow items={posts} locale={locale} isCMS={isCMS} />
      </Inview>
    </div>
  )
}

export default Template
