import React from 'react'
import Mark from 'components/Mark'
import ButtonLink from 'components/ButtonLink'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Template = ({ content }) => {
  const { title, body, buttonLink } = content
  return (
    <div className={`${styles.component} block pad-v-standard no-collapse`}>
      <div className={styles.markWrap}>
        <Mark isMobileLighter={true} />
      </div>
      <Inview className={`${styles.container} container fade-in up`}>
        <div className={styles.wrap}>
          {title && <h2>{title}</h2>}
          {body && <p>{body}</p>}
          {buttonLink && (
            <div className={styles.buttonWrap}>
              <ButtonLink content={buttonLink} />
            </div>
          )}
        </div>
      </Inview>
    </div>
  )
}

export default Template
