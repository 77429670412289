import React from 'react'
import ImageWrap from 'components/ImageWrap'
import TextExpand from 'components/TextExpand'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const TeamLeadBlock = ({ content, locale }) => {
  const { title, teamMember } = content
  const { name, image, bio } = teamMember?.data || {}
  return (
    <div className={`${styles.component} pad-v-standard`}>
      <Inview className='container fade-in up'>
        {title && <h5 className={styles.title}>{title}</h5>}
        {teamMember && (
          <div className={`grid-12 ${styles.teamMember}`}>
            {image && (
              <div className={styles.image}>
                <ImageWrap image={image} aspectRatio={5 / 6} />
              </div>
            )}
            <div className={styles.text}>
              <h3>{name}</h3>
              <h6>{teamMember?.jobTitle}</h6>
              <div className='p-smaller'>
                <TextExpand content={bio} locale={locale} />
              </div>
            </div>
          </div>
        )}
      </Inview>
    </div>
  )
}

export default TeamLeadBlock
