import React from 'react'
import ButtonLink from 'components/ButtonLink'
import Social from 'components/Social'
import LinkWrap from 'components/LinkWrap'
import Logo from 'assets/svg/vi/logo.svg'
import { StaticImage } from 'gatsby-plugin-image'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Footer = ({ footerLayout, locale }) => {
  const { isHidden, topBgColor } = footerLayout || {}
  if (isHidden) return <div />

  const localeStrings = {
    en: {
      email: 'info@prohr-intl.com',
      phone: '+86 21 5292 9000',
      address: `2105 Shanghai CITIC Square, 1168 Nanjing West Road
    Shanghai, China 200041`,
      copyright: `© Copyright ProHR. All rights reserved. <a href="https://lantern.digital" target="_blank">Website design</a>`,
    },
    zh: {
      email: 'info@prohr-intl.com',
      phone: '+86 21 5292 9000',
      address: `上海市静安区南京西路1168号中信泰富广场2105室
      邮编200041`,
      copyright: `©版权所有 上海汇华企业管理咨询有限公司 <a href="https://www.lanterndigital.com.cn" target="_blank">网站设计</a>`,
    },
  }
  const strings = localeStrings[locale]

  return (
    <footer className={styles.footer}>
      <div className={styles.imageWrap}>
        <div className='container'>
          <div
            className={styles.bgTop}
            style={{
              backgroundColor: topBgColor
                ? `var(--${topBgColor})`
                : 'transparent',
            }}
          />
          <div className={styles.bgBottom} />
          <Inview className={`${styles.image} fade-in up`}>
            <StaticImage
              src='../../assets/images/stock-blue-window.png'
              alt=''
            />
          </Inview>
        </div>
      </div>
      <div className={styles.mid}>
        <Inview className='container fade-in up'>
          <div className={styles.logo}>
            <LinkWrap to='/' className={styles.logo}>
              <Logo />
            </LinkWrap>
          </div>
          <div className={styles.contact}>
            <ButtonLink
              content={{
                text: strings.email,
                to: strings.email,
                linkType: 'email',
              }}
            />
            <p className='smaller'>{strings.phone}</p>
          </div>
          <div className={styles.address}>
            <p className='smaller'>{strings.address}</p>
          </div>
          <div className={styles.socialWrap}>
            <Social version='dark' />
          </div>
        </Inview>
      </div>
      <div className={styles.copyright}>
        <Inview className='container fade-in'>
          <div className={styles.inner}>
            <p
              className='smallest'
              dangerouslySetInnerHTML={{ __html: strings.copyright }}
            />
          </div>
        </Inview>
      </div>
    </footer>
  )
}

export default Footer
