import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import LineLink from 'components/LineLink'
import * as styles from './styles.module.scss'

const Component = ({ content, locale }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { excerpt, detail } = content || {}

  const toggleIsOpen = () => {
    setIsOpen(!isOpen)
  }
  const showClass = isOpen ? styles.show : ''

  const localeStrings = {
    en: {
      close: 'Close',
      more: 'More',
    },
    zh: {
      close: '关闭',
      more: '更多',
    },
  }
  const strings = localeStrings[locale]

  return (
    <div>
      <ReactMarkdown children={excerpt} />
      <div className={`${styles.detail} ${showClass}`}>
        <ReactMarkdown children={detail} />
      </div>
      <p onClick={toggleIsOpen}>
        <LineLink content={{ text: isOpen ? strings.close : strings.more }} />
      </p>
    </div>
  )
}

export default Component
