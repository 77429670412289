import React from 'react'
import ImageWrap from 'components/ImageWrap'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const TitleImageBlock = ({ content }) => {
  const { title, image } = content || {}
  return (
    <div className={`${styles.component} pad-v-standard bg-shade no-collapse`}>
      <Inview className='fade-in up'>
        <div className='container'>
          <h2 className={styles.title}>{title}</h2>
        </div>
        <div className={`container ${styles.containerImage}`}>
          <ImageWrap image={image} />
        </div>
      </Inview>
    </div>
  )
}

export default TitleImageBlock
